
  .alternativesTwo {
     width: calc(99.03% - 58px);
   /* height: calc(99.23% - 72px); */
    /* width:1px; */
    height:522px;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 30px 34px 42px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border: 2px solid rgba(235, 236, 241, 1);
    overflow-x: hidden;
  }

  .rectangle43-alternative{
    height:55px;
    width:55px;
  }

   .alternativesTwo::-webkit-scrollbar {
    width: 0.1em;
  }
   
  .alternativesTwo::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid black;
  }

  .alternatives {
    width: 43.27%;

    height: 7.17%;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 44px;
    margin-left: 7px;
  }
  .price-alternative{
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
  }
  .crocinAdvanceTablet-alternative {
    /* width: 59.31%; */
    width:100%;
    height: 5.61%;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 7px;
    margin-left: 20px;
  }
  .flexWrapperOne-alternative {
    padding: 0 0 0 142px;
    display: flex;
    align-items: center;
     margin-bottom: 26px;
  }

  @media (min-width: 1400px) and (max-width: 1500px){
     
    .packOf30{
      width: 150px !important;
    }
  }

  .num23 {
    width: 100%;
    height: 86.96%;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(255, 58, 68, 1);
  }
  .packOf30 {
     /* width: 88px;  */
    align-self: stretch;
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 500;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.6);
  }
  .packOf30Emphasis1 {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 500;
    line-height: 23px;
    color: rgba(0, 0, 0, 1);
  }
  .rectangle46-alternative {
    height: 0.45%;
    background-color: rgba(235, 236, 241, 1);
    margin-bottom: 22px;
    align-self: stretch;
  }
  .num34 {
    width: 500%;
    height: 86.96%;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(45, 167, 113, 1);
    margin-right: 10px;
  }
  .num23Two {
    width: 490.91%;
    height: 86.96%;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(255, 58, 68, 1);
    margin-right: 11px;
  }

  @media only screen and (max-width: 500px){
    .alternativesTwo{
      width: 110%;
height: 464.85px;
margin:auto;
position:relative;
right:30px;
    }
    .crocinAdvanceTablet-alternative {
      width:100%;
      font-size: 16px;
      margin-left:30px
    }
    .rectangle43-alternative{
      height:48px;
      width:48px;
    }
   
    .packOf30{
      width: 200px !important;
      position:relative;
      left:-20px;
    }
    .specs-alt{
      margin-top: 0px auto;
    }
  }

  @media (min-width: 1300px) and (max-width: 1500px){
    .specs-alt{
      margin-top:20px !important;
      
    }
  }

  @media only screen and (max-width: 380px){
    .alternativesTwo {
      padding-right: 10px !important;
    }
  }