.checkoutInfo1-info {
    border-radius: 10px;
    /* padding: 16px 448px 13px 18px; */
    padding:20px;
    display: flex;
    align-items: flex-start;
    border: 2px solid rgba(230, 230, 230, 1);
    width:90%;
    margin-left: 30px;
    margin-top: 50px;
    height:80px;
  }
  .login-wrapper{
   position: relative;
   bottom: 5px;
  }
  .person-name{
    position: relative;
    bottom:15px;
    right:160px;
  }
  .person-num{
    position: relative;
    top:17px;
    right:300px;
  }
  @media only screen and (max-width: 500px){
    .checkoutInfo1-info{
      margin-top:50px;
      margin-left:0px;
      border-radius: 7px;
      width:100%;
      height:60px;
  padding: 11.08px 0.88px 12.19px 12.43px;
  border: 1px solid rgba(230, 230, 230, 1);
    }
    
    .login-resp{
      font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 9.39841px !important;
line-height: 11px;
    }
    .login-wrapper{
     position:relative;
     right:20px;
     top:5px;
    }
   
    .a-wrapper{
      background-color: rgba(0, 0, 0, 1);
      margin-top: 4.03px;
      margin-right: 18.12px;
      border-radius: 50%;
      padding: 2.69px 5.1px 4.45px 6.04px;
      display: flex;
      align-items: center;
      width:auto ;
      height:auto;
    }
    .a{
      width: 100%;
      height: 100%;
      font-family: "Montserrat";
      font-size: 11px;
      font-weight: 600;
      line-height: normal;
      color: rgba(255, 255, 255, 1);
    }
   
    .name{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 10.741px !important;
      line-height: 13px;
      /* identical to box height */
      margin-top:-10px !important;
      color: #000000;

    }
    .login-wrapper{
      /* margin-right: 13.76px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    }
    .num{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 10.741px;
      line-height: 13px;
      position:relative;
      top:22px;
      right:50px;
    }
  }