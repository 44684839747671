.callaction-body {
  margin: 5% 0;
  border-radius: 12px;
  border: 4px dashed rgba(0, 0, 0, 0.09);
  padding: 30px 56px;
  display: flex;
  flex-direction: column;
}

.country-code {
  position: relative;
  top: 36px;
  font-size: 1.2rem;
  font-weight: 600;
  width: fit-content;
}

.input-call {
  outline: none;
  border: none;
  border-bottom: 2px solid #000000;
  margin-bottom: 20px;
  padding: 8px 52px;
  font-size: 1.2rem;
}

.confirm-number-btn {
  padding: 8px 0;
  border-radius: 7px;
  background-color: black;
  color: white;
}
