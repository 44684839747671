.cta-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.cta-title {
  font-family: "Poppins";
  font-size: 40px;
  margin: 20px;
  text-align: center;
}

.search-img {
  width: 20px;
  position: absolute;
  top: 19px;
  left: 25px;
}

.input-search {
  border: 2px solid black;
  padding-left: 66px;
}

.search-input-cta {
  margin-left: -2%;
  margin-top: 50px;
}

.search-cta {
  background-color: white;
  margin-bottom: 50px;
  margin-left: 4px;
  border-radius: 100px;
  padding: 22px 189px 22px 26px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(68, 88, 125, 1);
  width: 50%;
  height: 90%;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 400;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.5);
}

.cta-card {
  width: 200px;
  height: 180px;
  border-radius: 6.79px;
  padding: 20px 0px 0px 0px;
  margin-left: 50px;
  background-color: white;
}

.cta-card-desc {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
}

.cta-card-img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  margin-left: 60px;
}

@media only screen and (max-width: 450px) {
  .cta-container {
    margin-top: 50px !important;
  }
  .cta-title {
    text-align: left;
    font-family: "Poppins";
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    color: rgba(0, 0, 0, 1);
  }
  .cta-card {
    width: 110px;
    height: 110px;
    background-color: white;
    border-radius: 5px;
    padding: 21px 35px 26px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    margin-right: 20px;
    margin-top: 20px;
  }
  .cta-card-img {
    width: 38px;
    height: 38px;
    margin: auto;
  }
  .cta-card-desc {
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 500;
    line-height: 19px;
    color: rgba(0, 0, 0, 1);
    align-self: stretch;
    text-align: center;
  }
}
