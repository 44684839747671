.leftView {
    background-color: rgba(253, 250, 246, 1);
    border-radius: 10px;
   /* width: 500px;*/
     width:567px;
     height: 872px;
    /* padding: 51px 97px 186px 46px; */
    padding:50px 10px 100px 46px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .group6 {
    background-color: rgba(223, 241, 249, 1);
    margin-bottom: 40px;
    border-radius: 50%;
    padding: 13px 7.78px 9px 8px;
    display: flex;
    align-items: flex-start;
  }
  .message{
    background-color: #DFF1F9;
    width:70%;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-left: 70px;
  }

  .message p {
    padding:20px;
    font-size: 16px;
    font-weight: 500;
    font-family: poppins;
    line-height: 22px;
    
    text-align: center;
  }

  .screenshot20220403At12501 {
    flex: 1;
    align-self: stretch;
    object-fit: cover;
  }
  .sugamAushadhi {
    width: 100%;
    height: 8.19%;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 27px;
    margin-left: 52px;
    margin-top:80px;
  }
  .aBestPlaceToFindEveryMedinceAtIt {
    max-width: 354px;
    width: 85%;
    height: 9.13%;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 50px;
    margin-left: 53px;
  }
  .yourName {
    width: 70%;
    /* height: 4.09%; */
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
    margin-left: 60px; 
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    color: rgba(0, 0, 0, 0.5);
  }
  .rectangle62 {
    /* width: 60px; */
    height: 0.31%;
    background-color: rgba(0, 0, 0, 1);
    margin-bottom: 56px;
    margin-left: 52px;
    border-radius: 5px;
  }
  .flexWrapperTwo {
    background-color: rgba(0, 0, 0, 1);
    margin-left: 51px;
    border-radius: 10px;
    width: 372px;
    height: 60px;
    padding: 10px 90px 10px 90px; 
    /* padding:10px 50px 10px 20px; */
    display: flex;
    align-items: center;
  }
  .createAccount {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: white;
    text-align: center;
    flex: 1;
    align-self: stretch;
    cursor:pointer;
  }
  .flexWrapperThree {
    padding: 0 0 0 52px;
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    /* margin-left: 51px; */
  }
  .appStore {
    width: 63.38%;
    align-self: stretch;
    margin-right: 12px;
    object-fit: cover;
    cursor: pointer;
  }

  @media only screen and (max-width: 500px){
    .leftView {
      padding:30px 20px 30px 20px;
      height: 464px;
      width: 428px;
      position: relative;
      right:10px;
    }
    .sugamAushadhi{
        margin:auto;
        margin-bottom:10px !important;
        width: 90%;
        height: 8.19%;
        font-family: "Poppins";
        font-size: 25px;
        font-weight: 500;
        line-height: 52px;
        color: rgba(0, 0, 0, 1);
    }
    .aBestPlaceToFindEveryMedinceAtIt {
      max-width: 354px;
      width: 90%;
      height: 9.13%;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
     
      color: rgba(0, 0, 0, 0.6);
      margin:auto;
     
      padding-right: 10px !important;
    }
    .yourName {
     margin:auto;
     width:90%;
     margin-top:80px !important;
    }
    .rectangle62 {
      margin:auto;
    }
    .flexWrapperTwo {
      margin:auto;
      padding:20px 50px 10px 20px;
      width:350px;
      height:60px;
      margin-right: 25px;
      /* margin-top: -400px !important; */
    }
    .createAccount {
      margin:auto;
      width:100%;
     position: relative;
     bottom: 5px;
    }
    .flexWrapperThree {
       padding:0;
     
      margin: auto;
      /* margin-top: -100px !important; */
       margin-left: 10px; 
    }
    .appStore {
      padding:10px;
     width:170px !important;
    }
  
  }