.nav {
  display: flex;
  align-items: center;
  position: relative;
  top: 40px;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 80px !important;
}
.group1 {
  background-color: rgba(223, 241, 249, 1);
  margin-right: 20px;
  border-radius: 50%;
  padding: 13px 7.78px 9px 8px;
  display: flex;
  align-items: flex-start;
}
.screenshot20220403At12501 {
  flex: 1;
  align-self: stretch;
  object-fit: cover;
}
.frame17 {
  height: 41.3%;
  display: flex;
  z-index: 1;
}
.compareMedicines {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  margin-left: 29px;
}

.sign-in-box {
  z-index: 5;
}

.sign-in-btn {
  padding: 6px 34px;
}

@media screen and (max-width: 850px) {
  .nav {
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
  }
  .group1 {
    margin-right: auto;
  }
  .frame17 {
    width: 100%;
  }
  .compareMedicines {
    font-size: 0.9rem;
    margin-left: 0;
    margin-right: 20px;
  }
  .sign-in-box {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .btn {
    font-size: 0.8rem;
  }
}
