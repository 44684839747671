.group7 {
  background-color: white;
  border-radius: 12px;
  /* padding: 49.4px 52.36px 40.28px 50.64px; */
  /* padding:10px 0px 10px 20px; */
  padding: 49.4px 50.36px 40.28px 50.64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-right: 30px;
  width: 247px;
  height: 271.7px;
  height: 271.7px;
  width: 204px;
  padding: 45px 20px;
  text-align: center;
}
.imatinib {
  width: 90.28%;
  height: 21.98%;
  font-family: "Poppins";
  font-size: 31px;
  font-weight: 500;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 6.93px;
}
.genericForGleevec {
  width: 92.36%;
  height: 8.79%;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 48.22px;
}
.num3499 {
  width: 87.5%;
  height: 26.37%;
  font-family: "Poppins";
  font-size: 37px;
  font-weight: 600;
  line-height: 48px;
  color: rgba(248, 168, 0, 1);
  margin-bottom: 3.87px;
}
.retailPrice23445 {
  height: 10.44%;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  color: rgba(98, 149, 191, 1);
  align-self: stretch;
}

@media only screen and (max-width: 500px) {
  .group7 {
    width: 165.45px;
    height: 181.99px;
    margin: auto;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imatinib {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20.681px;
    line-height: 130%;
    margin-right: 25px;
  }
  .genericForGleevec {
    margin-right: -13px;
    width: 100px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8.27239px;
    line-height: 130%;
  }
  .num3499 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24.8172px;
    line-height: 130%;
    /* or 32px */
    color: #f8a800;
    margin-right: 25px;
  }
  .retailPrice23445 {
    font-family: "Poppins";
    width: 100px;
    position: relative;
    right: 15px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 9.92687px;
    line-height: 130%;
    /* identical to box height, or 13px */
    color: #6295bf;
  }
}
