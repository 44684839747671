.rectangle16 {
  /* width: 30.44%;
    height: 28.52%; */
  margin-bottom: 47.32px;
}

.offlinePickup {
  width: 100%;
  height: auto;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 9.3px;
}
.getTheBestPricesInTheWholeOfDelhThree {
  height: 21.69%;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  align-self: stretch;
}
.flexWrapperFour {
  background-color: rgba(213, 108, 113, 1);
  border-radius: 8px;
  padding: 30.48px 27.27px 34.58px 30.48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 230.34px;
  height: 250.01px;
}

.flexWrapper-about1 {
  background: #fae18b;
}
.flexWrapper-about2 {
  background: #a3d4a8;
}

.flexWrapper-about3 {
  background: #95d1f5;
}
.about-img {
  margin-top: 17px;
}

.about-title {
  font-family: "Poppins";
  font-size: 40px;
  margin-top: 20px;
  font-family: "Poppins";
  font-size: 40px;
}

.about-para {
  width: 90%;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 29.7px;
  color: rgba(0, 0, 0, 0.6);
  align-self: stretch;
  margin-top: 50px;
}
.card-about {
  margin-top: 100px;
}

@media only screen and (max-width: 450px) {
  .container-about {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .card-about {
    margin-top: 50px !important;
  }
  .about-title {
    font-size: 23px;
  }
  .about-para {
    font-size: 12px;
    width: 100%;
  }

  .flexWrapperFour {
    width: 160px;
    height: 180px;
    padding: 20px;
  }
  .offlinePickup {
    font-size: 15px;
  }
  .getTheBestPricesInTheWholeOfDelhThree {
    font-size: 10px;
  }
  .rectangle16 {
    margin-bottom: 10px;
  }
}
