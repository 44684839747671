@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");


.create-account-container {
    min-height: 100vh;
  }
  /* .create-account-createaccount2189 {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #DFF1F9;
  }  */


.auth-title{
    color: rgba(0, 0, 0, 1);
  height: auto;
  
  font-size: 40px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500px;
  line-height: 129.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}

.left-view-container{
   position:relative;
   right:50px;
}

.card-price-auth{
  display:grid;
  grid-template-columns:repeat(3, 0.2fr);
  position:absolute;
  top:30px;
}
.card-auth-0{
  position:absolute;
  top:590px;
  left:600px;
  animation: cardauth1 10s linear infinite;
}
.card-auth-1{
  position:absolute;
  top:590px;
  left:300px;
 
}
.card-auth-2{
  position:absolute;
  top:590px;

}
.card-auth-3{
  position:absolute;
  top:290px;
  left:600px;
  animation: cardauth2 10s linear infinite;
}
.card-auth-4{
  position:absolute;
  top:290px;
  left:300px;
}

.card-auth-5{
  position:absolute;
  top:290px;
  left:0px;
}
.card-auth-6{
  position:absolute;
  top:0px;
  left:600px;
}

.card-auth-7{
  position:absolute;
  top:0px;
  left:300px;
}

.card-auth-8{
  position:absolute;
  top:0px;
  left:0px;
}

.card-price-mob{
  display:grid;
  grid-template-columns:repeat(2, 1fr);
}




.auth-btn{
    width: 372px;
    height: 60px;
    color:white;
   
    object-fit: cover;
    border-color: transparent;
    border-radius: 10px;
    margin:auto;
    background-color: rgba(0, 0, 0, 1);
}