.productPageInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height:50vh;
  }
  .flexWrapperOne-info {
    background-color: rgba(223, 241, 249, 1);
    margin-bottom: 22px;
    border-radius: 10px;
    padding: 23px 108px 22px 76px;
    display: flex;
    align-items: center;
  }
  .prescriptionRequired {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: rgba(0, 0, 0, 1);
    flex: 1;
    align-self: stretch;
  }
  .flexWrapperTwo-info {
    background-color: white;
    margin-bottom: 32px;
    border-radius: 5px;
    padding: 27px 17px 33px 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .whyDoesMyMedicationLookDifferent {
    height: 38.92%;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    color: rgba(0, 0, 0, 1);
    align-self: stretch;
    margin-bottom: 13px;
  }
  .differentManufacturersProduceDifferen {
    max-width: 351px;
    width: 98.32%;
    height: 54.05%;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 1);
    margin-left: 1px;
  }
  .manufactureMicroLabsLtd {
    width: 100%;
    height: 3.97%;
    /* margin-bottom: 9px; */
    margin-left: 30px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: rgba(0, 0, 0, 1);
  }
  .mrp-info{
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
  }
  .manufactureMicroLabsLtdEmphasis0 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    
  }
  .num1Strip20Tablets {
    margin-top:-5px;
    width:100%;
    height: 3.97%;
    margin-bottom: 8px;
    margin-left: 30px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: rgba(0, 0, 0, 1);
  }
  .saltCompositionParacetamol650mg {
    width: 81.39%;
    height: 3.97%;
    margin-bottom: 5px;
    margin-left: 30px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: rgba(0, 0, 0, 1);
  }
  .num50Off {
    width: 14.64%;
    height: 3.79%;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(73, 165, 66, 1);
    margin-bottom: 6px;
    margin-left: 128px;
  }
  .ourPrice25 {
    width: 100%;
    height: 7.04%;
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 500;
    line-height: 39px;
    color: rgba(0, 0, 0, 1);
    margin-left: 30px;
    margin-top: 10px;
  }
 
  @media only screen and (max-width: 500px){
    .flexWrapperTwo-info{
      padding: 10px;
      margin:auto;
      margin-left: -10px;
      margin-top: 30px;
    }
    .manufactureMicroLabsLtd {
      width:100%;
      margin:auto;
      font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 136.5%;
    }
    
    .whyDoesMyMedicationLookDifferent {
      font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 143%;
/* identical to box height, or 23px */
color: #000000;
    }
    .differentManufacturersProduceDifferen {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 11.5136px;
      line-height: 155.5%;
      /* or 18px */
      color: #000000;
    }
    .manufactureMicroLabsLtdEmphasis0{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 136.5%;
      position:relative;
      right:20px;
      margin-right:-20px;
    }
    .mrp-info{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      position:relative;
      right:20px;
      top:40px;
    }
   .saltCompositionParacetamol650mg{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 136.5%;
    margin-top: 20px !important;
   }
    .num1Strip20Tablets{
      width:100%;
      margin:auto;
      margin-top: 15px !important;
    }
    .saltCompositionParacetamol650mg {
      width:100%;
      margin:auto;
    }
    .num50Off {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      /* identical to box height, or 21px */
      color: #49A542;
      position:relative;
      top:10px;
      right: 60px;
    }
    .ourPrice25 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 130%;
      /* identical to box height, or 39px */
      color: #000000;
      width:100%;
      position:relative;
      bottom:10px;
      right: 50px;
    }
  }

  @media (min-width: 1300px) and (max-width: 1500px){
    .flexWrapperOne-info{
      padding-right:50px !important;
    }
  }