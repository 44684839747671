.productPageProduct {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  
  .dolo650Tablet-product {
    width: 100%;
    height: 9.35%;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 35px;
  }
  .flexWrapperSeven-product {
    margin-bottom: 74px;
    display: flex;
    align-items: flex-start;
  }
  .flexWrapperNine-product {
    margin-right: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .flexWrapperTwo-product {
    background-color: white;
    margin-bottom: 22px;
    border-radius: 2px;
    padding: 0px 11px 8px 12px;
    display: flex;
    align-items: center;
  }
  .screenshot20220405At10061 {
    flex: 1;
    align-self: stretch;
    object-fit: cover;
  }


  .flexWrapperThree-product {
    background-color: white;
    padding: 8px 13px 7px 14px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .flexWrapperOne-product{
    background-color: white;
    height:350px !important; 
    border-radius: 2px;
    padding: 41px 33px 2px 52px;
    display: flex;
    align-items: flex-start;
  }
 