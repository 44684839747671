.left-container {
  width: 45vw;
  /* margin-left: 6vw; */
}

.home-title {
  font-family: "Poppins";
  font-size: 3.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  align-self: stretch;
  display: flex;
}

.vectorTwo {
  width: 24.18%;
  height: 24.18%;
  margin-left: 17.06px;
}

.search-input {
  margin-left: -2%;
  margin-top: 50px;
}

.card:hover {
  cursor: pointer;
  border: 1px solid black;
}

.search {
  /* background-color: white;
  margin-bottom: 50px;
  margin-left: 4px;
  border-radius: 100px;
  padding: 22px 189px 22px 26px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(68, 88, 125, 1);
  width: 83.24%;
  height: 90%;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 400;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.5); */

  padding: 10px 60px;
  border-radius: 25px;
  border: 2px solid;
}

.search-icon {
  position: absolute;
  width: 24px;
  top: 11px;
  left: 16px;
}

.card-container {
  background-color: white;
  border-radius: 5px;
  padding: 21px 35px 26px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 32px;
  height: 154px;
  width: 160px;
}

.box-img {
  width: 50px;
  height: 50px;
  margin-bottom: 19px;
  margin-left: 23px;
}

.box-desc {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  align-self: stretch;
  text-align: center;
}

@media screen and (max-width: 970px) {
  .home-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 730px) {
  .home-title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .left-container {
    width: 90vw;
  }
  .home-headings {
    margin-top: 1rem;
  }
  .home-headings {
    padding-top: 0;
  }
  .left-container {
    margin-top: 0;
  }
}

@media only screen and (max-width: 450px) {
  .left-container {
    margin: auto;
  }
  .home-title {
    font-size: 30px;
    font-family: "Poppins";
    margin: auto;
    margin-left: 10px;
  }
  .home-headings {
    margin-top: 2rem;
  }
  .search-home {
    width: 95%;
    border-radius: 300px !important;
    height: 50px;
    margin-top: 30px !important;
    margin-left: 10px;
    margin: auto;
    padding-bottom: 10px;
  }
  .search-img {
    margin-bottom: 10px !important;
  }
  .card-container {
    width: 90px;
    height: 100px;
    padding: 10px;
    margin-left: 5px;
  }
  .box-img {
    height: 40px;
    width: 40px;
  }
  .box-desc {
    font-size: 10px;
    line-height: 10px;
    margin: auto;
  }
}
