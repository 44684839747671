.product-landing{
  margin-top: 80px;
}
.component2-view {
 display: flex;
 align-items: flex-start;
 margin-left: 80px;
 margin-bottom: 30px;
}
.allMedications-view {
  width: 154px;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: rgba(248, 168, 0, 1);
  align-self: stretch;
  margin-right: 10px;
}
.chevronRight-view {
  /* width: calc(8.36% - 18px);
  height: calc(92.31% - 12px); */
  /* position: relative;
  right:600px; */
  width:auto;
  height:auto;
  margin-right: 10px;
  padding: 6px 9px;
  display: flex;
  align-items: center;
}
.vector-view {

  align-self: stretch;
  object-fit: cover;
}
.dolo650-view {
  width: 30.01%;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: rgba(33, 32, 33, 1);
  align-self: stretch;
}

  .hyphen {
    width: 50%;
    height: 103.33%;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    color: white;
    position: absolute;
    left: 7px;
    bottom: -1px;
  }
  .ellipse2 {
    width: 100%;
    height: 100%;
    background-color: transparent-white;
    border-radius: 50%;
    position: relative;
  }
  .num01 {
    width: 22.43%;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    color: white;
    align-self: stretch;
    margin-right: 12px;
  position:relative;
  bottom:4px;
  }


   @media only screen and (max-width: 500px){
    .component2-view{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      margin:auto;
      margin-bottom:10px;
      /* identical to box height, or 26px */
      color: #F8A800;
    }
   }