.header-mobnav{
    display: flex;
    align-items: flex-start;
    justify-content: space-between !important;
    position:relative;
    top:40px;
    margin-bottom:60px;
  }
  .sugamAushadhi-mobnav {
    width:100%; 
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    align-self: stretch;
    margin-right: 140px;
    /* margin-right: 60%; */
  }
  .alignRight-mobnav{
    width: 100%;
    height: 88.89%;
  }
  @media only screen and (max-width: 400px){
    .header-mobnav{
      right:15px;
    }
    .sugamAushadhi-mobnav {
      margin-right: 120px;
    }
  }