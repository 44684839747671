.right-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  width: 40vw;
  z-index: 0;
}
.fade-container-top {
  position: absolute;
  top: 0;
  right: 0;
  height: 350px;
  width: 100%;
  background: linear-gradient(
    180deg,
    #dff1f9 17.3%,
    rgba(223, 241, 249, 0) 78.03%
  );
  /* opacity: 0.5; */
  z-index: 2000;
}

.fade-container-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background: linear-gradient(
    180.28deg,
    rgba(223, 241, 249, 0) 17.45%,
    rgba(223, 241, 249, 0.83) 86.54%
  );
  /* opacity: 0.5; */
  z-index: 2000;
}

/* Left  */
.card-1 {
  position: absolute;
  top: 700px;
  animation: card1 10s linear infinite;
}

.card-2 {
  position: absolute;
  top: 400px;
  animation: card2 10s linear infinite;
}

.card-3 {
  position: absolute;
  top: 100px;
  /* left: 250px; */
  animation: card3 10s linear infinite;
}

/* Right  */

.card-4 {
  position: absolute;
  top: 50px;
  left: 250px;
  animation: card4 10s linear infinite;
}
.card-5 {
  position: absolute;
  top: 500px;
  left: 250px;
  animation: card5 10s linear infinite;
}

.card-6 {
  position: absolute;
  top: 950px;
  left: 250px;
  animation: card6 10s linear infinite;
}

/* Left  */

@keyframes card1 {
  0% {
    top: -100px;
  }
  100% {
    top: calc(100vh + 650px);
  }
}

@keyframes card2 {
  0% {
    top: -500px;
  }
  100% {
    top: calc(100vh + 300px);
  }
}

@keyframes card3 {
  0% {
    top: -800px;
  }
  100% {
    top: calc(100vh + 10px);
  }
}

/* Right  */
@keyframes card4 {
  0% {
    top: calc(100vh + 100px);
  }
  100% {
    top: -650px;
  }
}

@keyframes card5 {
  0% {
    top: calc(100vh + 500px);
  }
  100% {
    top: -300px;
  }
}

@keyframes card6 {
  0% {
    top: calc(100vh + 800px);
  }
  100% {
    top: 0px;
  }
}
@media screen and (max-width: 970px) {
  .right-container {
    height: 572px;
  }
}

@media screen and (max-width: 770px) {
  .right-container {
    height: 128vh;
  }
}
