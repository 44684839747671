.allMedicationsAcidRefluxAllergiesAn {
    max-width: 154px;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color:black;
  }

  .item:hover {
    cursor: pointer;
    color:  #F8A800;
  }
